import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueAxios from 'vue-axios';
import axios from 'axios';
Vue.use(VueAxios, axios);

Vue.config.productionTip = false

import { global } from './plugins/global.js'
import { registerLicense, L10n } from '@syncfusion/ej2-base';
import * as EJ2_LOCALE from "../node_modules/@syncfusion/ej2-locale/src/es.json";

L10n.load({ es: EJ2_LOCALE.es })

registerLicense('ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Rd0RiUXxbdXRTR2Nc');

Vue.mixin({
  data() {
    return {
      gb: global
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
